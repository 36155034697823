<template>
  <div class="relative flex flex-col items-center justify-end min-h-[80vh] lg:min-h-screen 2xl:min-h-[90vh] overflow-hidden lg:justify-center">
    <SectionHeroBackground :background-image="backgroundImage"
                           :background-image-mobile="backgroundImageMobile"
                           :background-video="backgroundVideo"
                           :background-video-mobile="backgroundVideoMobile"
    />

    <div class="absolute inset-0 z-0 w-full h-full pointer-events-none"
         :style="{
           backgroundImage: `linear-gradient(180deg, rgba(10, 10, 10, 0) 27.44%, #0A0A0A 92.94%)`
         }"
    />

    <Container class="relative z-10 w-full pt-56 lg:pb-0 lg:pt-0"
               :class="cards && cards.length > 0 ? 'pb-52' : 'pb-16'"
    >
      <h1 class="text-5xl leading-tight text-white h1 md:text-8xl lg:w-2/3"
          :class="[{ 'text-rainbow': boldGradient === 'rainbow' }]"
          v-html="title"
      />

      <div v-if="copy"
           class="w-3/4 max-w-3xl mt-12 text-2xl text-white lg:w-1/2"
      >
        <div v-html="copy" />
      </div>

      <div v-if="cta"
           class="flex mt-12"
      >
        <ButtonBase :label="cta.title"
                    :type="cta.type"
                    :href="cta.href"
                    @click="handleClick(cta)"
        />
      </div>
    </Container>
  </div>

  <SectionHeroFloatingCards v-if="cards && cards.length > 0"
                            :cards="cards"
  />
</template>

<script setup lang="ts">
import type { CtaType } from '../../../types/button';
import type { CardType } from '../../../types/cards';
import { useNuxtApp } from '#imports';
import { useRoute } from 'vue-router';

interface HeroPrimaryProps {
  title: string;
  copy: string | null;
  backgroundImage?: string;
  backgroundImageMobile?: string;
  backgroundVideo?: string;
  backgroundVideoMobile?: string;
  cta?: CtaType;
  boldGradient: 'rainbow' | 'none';
  cards: Array<CardType>;
}

withDefaults(defineProps<HeroPrimaryProps>(), {
  cta: false,
  backgroundImage: '',
  backgroundImageMobile: '',
  backgroundVideo: '',
  backgroundVideoMobile: ''
});

const { $event } = useNuxtApp();
const route = useRoute();

function handleClick(cta: CtaType) {
  if (!cta || !cta.title) return;
  if (route.path.includes('for-media')) return $event('Contact', { props: { 'Media page': cta.title } });
}
</script>

<!-- We require classes for scoped to work -->
<style scoped>
.h1 strong {
  @apply text-gray-100 text-opacity-50;
}
.h1.text-rainbow strong span {
  background: linear-gradient(to right, #ff0800 0%, #ffff00 17%, #2aff00 34%, #00fff2 51%, #004cff 68%, #f400fc 85%, #ff0800 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-animation: 'slide' 5s infinite linear forwards alternate;
  animation: 'slide' 5s infinite linear forwards alternate;
  background-size: 700px 150px;
  text-align: center;
  color: white;
  -webkit-text-fill-color: transparent;
}

@keyframes slide {
  0% {
    background-position-x: 0%;
  }
  100% {
    background-position-x: 100%;
  }
}
</style>
